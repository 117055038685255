@import url(https://fonts.googleapis.com/css?family=Cabin+Sketch|Lato);
/* clear default */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  font-family: "Cabin Sketch", cursive;
}

button {
  padding: 1px 7px 2px;
}

#App {
  min-height: 100vh;
  background-color: #071219;
  background: -webkit-linear-gradient(280deg, #454d53, #071219 100%);
  background: linear-gradient(170deg, #454d53, #071219 100%);
  position: relative;
}

main {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 40px);
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media screen (max-width: 460px) {
  main {
    min-height: calc(100vh - 52px);
  }
}

.title-wrapper {
  max-width: 660px;
  width: 100%;
  margin: 2rem auto;
  text-align: center;
  color: #fff;
}

a:-webkit-any-link {
  text-decoration: none;
  color: inherit;
}

/* largely burger menu defaults from https://github.com/negomi/react-burger-menu */

.Menu-wrap {
  height: 40px;
}

@media screen and (max-width: 460px) {
  .Menu-wrap {
    height: 54px;
  }
}

/* custom svg */
.burger-icon {
  height: 16px;
  width: 16px;
}

@media screen and (max-width: 460px) {
  .burger-icon {
    height: 24px;
    width: 24px;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  fill: grey;
  flex-shrink: 0;
  justify-content: center;
  -webkit-transition: background 0.25s ease-out, margin-left 0.25s ease-out;
  transition: background 0.25s ease-out, margin-left 0.25s ease-out;
  width: 43px;
  height: 40px;
  margin-right: 15px;
}

@media screen and (max-width: 460px) {
  .bm-burger-button {
    height: 54px;
    width: 54px;
    margin: 0;
  }
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 18px;
  width: 18px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

.bm-cross:hover {
  background: #fff;
}

/* General sidebar styles */
.bm-menu {
  background: #fac7ae;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Wrapper for item list */
.bm-item-list {
}

.bm-item {
  cursor: pointer;
  display: block;
  outline: none;
  text-decoration: none;
  color: #0a0f54;
  padding: 0.75rem 1rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.bm-item:hover {
  color: #fff;
}

.bm-item span {
  margin-left: 0.5rem;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3) !important;
}

