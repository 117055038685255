/* largely burger menu defaults from https://github.com/negomi/react-burger-menu */

.Menu-wrap {
  height: 40px;
}

@media screen and (max-width: 460px) {
  .Menu-wrap {
    height: 54px;
  }
}

/* custom svg */
.burger-icon {
  height: 16px;
  width: 16px;
}

@media screen and (max-width: 460px) {
  .burger-icon {
    height: 24px;
    width: 24px;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  fill: grey;
  flex-shrink: 0;
  justify-content: center;
  transition: background 0.25s ease-out, margin-left 0.25s ease-out;
  width: 43px;
  height: 40px;
  margin-right: 15px;
}

@media screen and (max-width: 460px) {
  .bm-burger-button {
    height: 54px;
    width: 54px;
    margin: 0;
  }
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 18px;
  width: 18px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

.bm-cross:hover {
  background: #fff;
}

/* General sidebar styles */
.bm-menu {
  background: #fac7ae;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Wrapper for item list */
.bm-item-list {
}

.bm-item {
  cursor: pointer;
  display: block;
  outline: none;
  text-decoration: none;
  color: #0a0f54;
  padding: 0.75rem 1rem;
  user-select: none;
}

.bm-item:hover {
  color: #fff;
}

.bm-item span {
  margin-left: 0.5rem;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3) !important;
}
