#App {
  min-height: 100vh;
  background-color: #071219;
  background: linear-gradient(170deg, #454d53, #071219 100%);
  position: relative;
}

main {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 40px);
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media screen (max-width: 460px) {
  main {
    min-height: calc(100vh - 52px);
  }
}

.title-wrapper {
  max-width: 660px;
  width: 100%;
  margin: 2rem auto;
  text-align: center;
  color: #fff;
}

a:-webkit-any-link {
  text-decoration: none;
  color: inherit;
}
