@import url("https://fonts.googleapis.com/css?family=Cabin+Sketch|Lato");

/* clear default */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  font-family: "Cabin Sketch", cursive;
}

button {
  padding: 1px 7px 2px;
}
